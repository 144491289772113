.event-body-container {
    text-decoration: none;
    position: relative;
}
.event-body-container .tooltip-text {
    background-color: #fff;
    z-index: 10;
    /* Fade effect */
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 400;
    border: #e0e0e0 solid 1px;
    padding: 2px 6px;
}
.event-body-container #tooltip-id {
    display: none;
}
.event-body-container:hover #tooltip-id {
    display: block;
    position: fixed;
    overflow: hidden;
    opacity: 1;
}
