:root {

}

.container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 49%;
}

.new-item {
    padding: 1px;
}

.exist-item {
    padding: 10px;
}

.modal-scrollable-content {
    overflow-y: hidden;
    height: 90vh;
}

/* Table Style */
/* MAKE LEFT COLUMN FIXEZ */
.dp tr > :first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: #FFFFFF;
}

.dp table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}

.dp table thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
    padding: 1px 0;
}

.time-field {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.pmol-comments-my-calendar {
    padding: 0 10px;
    /*margin-top: -32px;*/
}

.footer-btn{
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 0 10px;
}

.finish-check-box{
    display: flex;
    align-items: flex-end;
    margin-bottom: -20px
}