.divider-styles {
    margin: 5px 0;
}

.time-line-wrapper {
    display: flex;
    flex-direction: column;
}

.time-line-item-wrapper {
    padding: 8px;
}

.time-line-date-style {
    display: flex;
    flex-direction: column;
}

.time-line-title-style {
    display: flex;
    flex-direction: column;
}

#task-other-people-input {
    min-height: 20px;
    min-width: 244px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(209, 209, 209) rgb(209, 209, 209) rgb(97, 97, 97);
    border-image: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    text-align: left;
    color: #242424;
    padding: 5px 10px;
}