.full-width {
    width: 100%;
}

.main-layout-document-pane-stack {
    width: 98%;
    height: calc(100vh - 183px);
    overflow: auto;
    align-self: center;
    padding: 1rem;
}
