:root {
    --pmolWork: #92E950;
    --pmolTravel: #F35325;
    --pmolLoad: #04A6F0;
}

.day {
    height: 10px !important;
    background-color: #f2f2f2;
}

.kanbanTable tr {
    height: 10px;
}

.kanbanTable {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    border-collapse: collapse;
    width: 100%;
}

.kanbanTable td, .kanbanTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

/*.kanbanTable tr:nth-child(even){background-color: #f2f2f2;}*/

.kanbanTable tr:hover {
    background-color: #ddd;
}

.kanbanTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: white;
}

.pmolbox {
    height: 28px;
    width: fit-content;
    margin: 5px;
    background-color: #36c2f6;
    padding: 8px;
    display: initial;
}

.dragBox {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    padding: 2px;
    margin: 2px;
    text-align: center;
    width: fit-content;
    height: fit-content;
}

.noneDragBox {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    padding: 5px;
    margin: 5px;
    text-align: center;
    width: fit-content;
    height: fit-content;
}

.dragBox:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    cursor: move;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 15px #888888;
}

.noneDragBox:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    cursor: default;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 15px #888888;
}

.dropBoxActive {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    background-color: rgb(235, 242, 250);
    transition: background-color 275ms ease 0s;
    cursor: pointer;
    border: 3px dashed #1c87c9 !important;
    color: #000000;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dropBoxTdActive {
    font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important ;
    background-color: rgb(235, 242, 250);
    transition: background-color 275ms ease 0s;
    cursor: pointer;
    border: 3px dashed #1c87c9!important;
    color:#000000;
    font-weight: bolder;
    align-items:center;
    justify-content:center;
    position: relative;
}
#dropOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgb(235, 242, 250);
    align-items:center;
    justify-content:center;
    text-align: center;
}

#dropOverlay #text{
    padding-top: 10%;
}
.th-automation #dropOverlay #text{
    padding-top: 2% !important;
}
.th-automation .thaEventWrapper.concrete-mixer.notLanding #dropOverlay #text{
    display: none !important;
}

.rbc-time-header-gutter #dropOverlay #text{
    display: none !important;
}

.dragBox i {
    vertical-align: middle;
}

.dropBox {
    padding: 2px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

/*big calender style*/
@charset "UTF-8";
.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}

button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled].rbc-btn {
    cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.rbc-calendar {
    /*font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important ;*/
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-row-bg {
    border-right: 1px solid #DDD !important;
}

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #999999;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}

.rbc-header {
    width: 250px;
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #FFF;
}

.rbc-header + .rbc-header {
    border-left: 1px solid #DDD;
}

.rbc-customeHeader {
    position: relative;
}

.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
}

.rbc-row-content-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
    display: none;
}

.rbc-today {
    background-color: #ffffff;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
}

.rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
}

 .rbc-toolbar .ms-style button{
    border: none!important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.rbc-toolbar button:focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.rbc-btn-group button + button {
    margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #3174ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #3b99fc;
}

.rbc-event-label {
    font-size: 80%;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
}

.rbc-month-view {
    position: relative;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD;
}

.rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
}

.rbc-date-cell.rbc-now {
    font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0 0%;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.rbc-overlay > * + * {
    margin-top: 1px;
}

.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}

.rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    height: 40px !important;
    vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #DDD;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 3px 5px;
    text-align: center;
    border-bottom: 1px solid #DDD;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}

.rbc-agenda-time-cell {
    text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »';
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}

.rbc-agenda-event-cell {
    width: 100%;
}

.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
    flex: 1;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #DDD;
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
    flex: none;
}

.rbc-label {
    padding: 0 5px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}

.rbc-day-slot {
    position: relative;
}

.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-day-slot .rbc-background-event {
    opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
}

.rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #DDD;
    z-index: 10;
    margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-view-resources .rbc-day-slot {
    min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0px;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
    font-weight: bold;
}

.rbc-day-header {
    text-align: center;
}

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid #DDD;
    min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD;
}

.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}

.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}

.rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD;
}

.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD;
}

.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #FFF;
    flex-shrink: 0;
}

.rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #DDD;
    overflow-y: auto;
    position: relative;
}

.rbc-time-content > .rbc-time-gutter {
    flex: none;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
}

.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #74ad31;
    pointer-events: none;
}


.rbc-time-header-cell {
    border-top: 1px solid #DDD !important;
}

.dropBoxSubItem {
    overflow-wrap: break-word;
    width: 100%;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px;
    font-size: 11px;
}

.gridDragBox{

}

.customEventWrapper.travel .rbc-event{
    /*background-color: #EB5757  !important;*/
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.travel .rbc-event .rbc-event-label {
    color: white!important;
}

.customEventWrapper.work .rbc-event{
    /*background-color: #07AA6B  !important;*/
    background-color: var(--pmolWork)  !important;
}

.customEventWrapper.load .rbc-event{
    /*background-color: #07A6B2  !important;*/
    background-color: var(--pmolLoad)  !important;
}
.customEventWrapper.load .rbc-event .rbc-event-label {
    color: white!important;
}

.customEventWrapper .rbc-event{
    display: block !important;
}

.customEventWrapper .rbc-event-label, .dropBox{
    color: black !important;
}

.rbc-allday-cell{
    display: none;
}

.project-day_planing .rbc-time-header.rbc-overflowing{
    height: 70px;
}

.project-day_planing .rbc-event-label {
    font-size: 72%;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}
.pmol-title-pdp{
    font-size: 13px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
    z-index: 1;
    width: fit-content;
}

.three-dots {
    cursor: pointer;
    font-size: 12px;
    padding: 0 5px;
}

.pmol-time{
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
    min-width: 150px;
    margin-right: 5px;
}
.pmol-title{
    padding-left: 10px;
}
.customEventWrapper.travel .three-dots{
    color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .three-dots{
    color: var(--pmolWork)  !important;
}

.customEventWrapper.load .three-dots{
    color: var(--pmolLoad)  !important;
}
.customEventWrapper.travel .pmol-time{
    color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .pmol-time{
    color: var(--pmolWork)  !important;
}

.customEventWrapper.load .pmol-time{
    color: var(--pmolLoad)  !important;
}




.customEventWrapper.travel .tool-count {
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .tool-count {
    background-color: var(--pmolWork)  !important;
}
.customEventWrapper.load .tool-count {
    background-color: var(--pmolLoad)  !important;
}
.customEventWrapper.travel .button__badge,.tool-count {
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .button__badge,.tool-count {
    background-color: var(--pmolWork)  !important;
}

.customEventWrapper.load .button__badge,.tool-count {
    background-color: var(--pmolLoad)  !important;
}

.navbar-pmol {
    font-size: 22px;
    padding: 0px 0px;
}

/* Define what each icon button should look like */
.button {
    display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
    position: relative; /* All 'absolute'ly positioned elements are relative to this one */
    padding: 2px 5px; /* Add some padding so it looks nice */
    color: black;
}

/* Make the badge float in the top right corner of the button */
.button__badge {
    border-radius: 5px;
    color: white;

    padding: 1px 3px;
    font-size: 10px;

    position: absolute; /* Position the badge within the relatively positioned button */
    top: 0;
    right: 0;
}
.tool-count{
    font-size: 9px;
    margin-left: 10px;
    border-radius: 10px;
    width: 34px;
    text-align: center;
    padding-top: 3px;
    color: white;
    height: 15px;
}

.vertical-line{
    background-color: #e3e0de;
    width: 1px;
    height: 30px;
    margin-left: 3px;
    margin-right: 3px;
}

.rbc-time-view-resources .rbc-time-header-content{
    min-width: 330px!important;
}
.rbc-time-content > .rbc-day-slot{
    min-width: 329px!important;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    border: none;
}

.pie-chart{
    border-radius: 50%;
}
.pie-chart:hover{
    background-color: rgb(245, 250, 250);
    transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 10s;
    /*border: 3px rgba(18, 18, 18, 0.68) !important;*/
    box-shadow: 2px 2px 6px #acacac;
}

.plan-board-calender-with-borders .rbc-header {
    /*border: 1px solid black !important;*/
    padding: 0px !important;
}

.plan-board-calender-with-borders .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
    /*border: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-time-content > * + * > * {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-time-header-gutter {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-timeslot-group {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .weekDate-364 {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .dropBox {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .pmol-dropBox {
    padding: 2px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.plan-board-calender-with-borders.show-all-in-screen .rbc-time-content > .rbc-day-slot {
    min-width: 10px!important;
}
.plan-board-calender-with-borders.show-all-in-screen .rbc-time-view-resources .rbc-time-header-content{
    min-width: 10px!important;
}

.th-automation{
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}

.th-automation .rbc-time-content > .rbc-day-slot {
    min-width: 10px!important;
}
.th-automation .rbc-time-view-resources .rbc-time-header-content{
    min-width: 10px!important;
}
.th-automation .rbc-time-header-content{
    border: none!important;
}
.th-automation .rbc-time-header{    /* //TODO: uncomment for sticky header */
    position: sticky;
    top: 90px;
    z-index: 11;
    background: white;
}
.th-automation .rbc-row-resource .rbc-header{
    padding: 0!important;
}
/*.project-day_planing .rbc-event-label{*/
/*    visibility: hidden;*/
/*    font-size: 3px;*/
/*}*/

.th-automation .hide-time .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter  {
    display: none;
}
.th-automation .hide-time .rbc-label.rbc-time-header-gutter  {
    display: none;
}
.th-automation .isFirst .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter  {
    display: inherit;
}
.th-automation .custom-toolbar{
    min-height: 47px;
    text-align: center;
    border: 1px solid #ddd;
    font-weight: bold;
    font-size: 90%;
    color: #e5e5e5;
    position: sticky;  /* //TODO: uncomment for sticky header */
    top: 0;
    z-index: 11;
}
.th-automation .custom-toolbar .pbsDynamicAttributes{
    min-height: 47px;
    text-align: center;
    font-weight: normal;
    font-size: 90%;
}
.th-automation .rbc-event-label{
   display: none;
}
.th-automation .rbc-event{
   left: 10% !important;
    max-width: 85%!important;
    min-width: 85%!important;
}
.th-automation .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
   /*border: none!important;*/
    border-radius: 0!important;
}
.th-automation  .virtual_product .rbc-event{
    background-color: #F7D166!important;
    max-width: 85%!important;
    border: #2D3A4A 1px solid!important;

}
.th-automation  .pump_product .rbc-event{
    background-color: #70ad47!important;
    max-width: 56px!important;
    border: #2D3A4A 1px solid!important;
    min-width: 56px!important;

}
.th-automation  .pump-car.pmol_travel1 .rbc-event{
    border-style: solid solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_loading .rbc-event{
    background-color: var(--pmolLoad)!important;
    max-width: 85%!important;
    border-style: solid solid none solid;
    border-color: #000000;
    border-width: 2px;
}

.th-automation  .pmol_travel1 .rbc-event{
    background-color: var(--pmolTravel)!important;
    max-width: 85%!important;
    border-style: none solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_unloading .rbc-event{
    background-color: #70ad47!important;
    max-width: 85%!important;
    border-style: none solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_travel2 .rbc-event{
    background-color: var(--pmolTravel)!important;
    max-width: 85%!important;
    border-style: none solid solid solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_loading .rbc-event i{
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}
.th-automation  .pmol_travel2 .rbc-event i{
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.th-automation .concrete-mixer .rbc-event{
   background-color: #70ad47!important;
    max-width: 56px!important;
    border: none!important;
    min-width: 56px!important;
}
.th-automation .concrete-mixer.notLanding .rbc-event{
   background-color: #ed7d31!important;
}
.th-automation .concrete-mixer.meter .rbc-event{
   background-color: #ffffff !important;
   border: #2D3A4A 1px solid;
}
.th-automation .concrete-mixer.meter .rbc-event, .rbc-day-slot .rbc-background-event{
    border: #2D3A4A 1px solid!important;
    border-radius: 0!important;
}
.th-automation .concrete-mixer .rbc-event, .rbc-day-slot .rbc-background-event{
    border: #2D3A4A 1px solid!important;
    border-radius: 0!important;
}
.th-automation-bottom .rbc-event-label{
    display: none;
}

.th-automation-bottom .rbc-event{
    /*border: 1px solid #000000!important;*/
    border-radius: 0!important;
    background-color: transparent;
    border: none !important;
    cursor: default;
}

.th-automation-bottom .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    /*border: #000000 1px solid!important;*/
}

.delete-button {
    color: #000000;
    fontSize: 14px;
    cursor: pointer;
    margin: 4px;
}

.delete-button:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 11px #888888;
}

@media only screen and (min-width: 2000px) {
    .th-automation .custom-calender{
      height: 100% !important;
    }
    .th-automation .concrete-mixer .rbc-event{
        max-width: 70px!important;
        min-width: 70px!important;
    }
}
@media only screen and (min-width: 3000px) {
    .th-automation .custom-calender{
      height: 80% !important;
    }
    .th-automation .concrete-mixer .rbc-event{
        max-width: 80px!important;
        min-width: 80px!important;
    }
}
.th-automation .custom-calender.set-height{
    height: 100% !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    width: 100% !important;
    /*border-top: 4px solid;*/
    /*margin: -1px auto;*/
}

.rbc-time-header-gutter{
    width: 50.9688px !important;
    min-width: 50.9688px !important;
    max-width: 50.9688px !important;
}

.rbc-time-gutter.rbc-time-column{
    width: 50.9688px !important;
    min-width: 50.9688px !important;
    max-width: 50.9688px !important;
}
