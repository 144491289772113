.calendarBottomText {
    text-anchor: middle;
    fill: #333;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.calendarBottomTextWeek {
    width:'100%';
    fill: #333;
}

.calendarTopTick {
    stroke: #e6e4e4;
}

.calendarTopText {
    text-anchor: middle;
    fill: #555;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.calendarHeader {
    fill: #ffffff;
    stroke: #e0e0e0;
    stroke-width: 1.4;
}
