/*.ms-DetailsList{*/
/*height: calc(100vh);*/
/*}*/

.header-bar {
    background-color: #ee7423;
    border-bottom: 1px solid #854114;
    color: white;
    align-items: center;
}

.header-bar a {
    color: white;
    display: flex;
}

.header-bar a:hover {
    text-decoration: none;
}

.header-bar i {
    font-size: 2rem;
    font-weight: bold;
}

.header-bar i:hover {
    text-decoration: none;
}

.header-bar label {
    color: white;
    font-size: 1.1rem;
    font-weight: normal;
    margin-left: 0.2rem !important;
}

.recent-project-bar {
    background-color: #e6e6e6;
    border-bottom: 1px solid #d2d2d2;
    white-space: nowrap;
}

.recent-project-bar a {
    font-size: 0.8rem;
    color: #323130;
    display: flex;
}

.recent-project-bar i {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-right: 0.2rem;
    opacity: 0.6;
}

.recent-project-bar i.root-199 {
    opacity: 0.9;
    padding-left: 2px;
}

.recent-project-bar .gap-control {
    margin-left: 20px !important;
}

.content-holder {
    width: calc(100% - 30px);
    border: 1px solid #bab8b7;
    margin-top: 15px !important;
}

.content-holder-inner {
    margin-top: 15px !important;
    overflow: hidden;
}

.holder-inner {
    border: 1px solid #d2d2d2;
}

.title-bar-1 {
    border-bottom: 1px solid #bab8b7;
    width: 100%;
    padding: 0.5rem;
}

.title-bar-1 a {
    line-height: 0;
    display: flex;
    align-items: center;
}

.title-bar-1 a:hover,
.title-bar-1 a:hover i {
    text-decoration: none;
}

.title-bar-1 i {
    font-size: 1.4rem;
    line-height: 1.2rem;
    color: #323130;
    padding-right: 0.5rem;
}

.title-bar-1 label {
    padding: 0;
    cursor: pointer;
}

/* match with the current app */
.title-bar-1.title-bar-1.title-bar-inner {
    background-color: #fafafa;
    border-bottom: 1px solid #d2d2d2;
}

.title-bar-1.title-bar-inner,
.title-bar-1.title-bar-inner label {
    cursor: default;
}

.title-bar-1.title-bar-inner label {
    margin-left: 0;
}

.inner-container {
    padding: 0.5rem;
}

.project-detail-list.inner-container {
    padding: 0 0 1rem 1rem;
    width: 80%;
    height: 80vh;
}

.project-list-header {
    padding: 0.5rem;
    min-height: 46px;
    border-bottom: 1px solid #d2d2d2;
}

.project-list-header.right-panel {
    padding: 0 0.5rem 0 0;
}

/* .project-list-header button,
.ms-CommandBar,
.ms-CommandBar .ms-Button.ms-Button--commandBar {
    background-color: #ffffff !important;
}
.project-list-header button.ms-Button--default {
    background-color: #ffffff !important;
} */
.ms-CommandBar {
    padding-right: 0;
    padding-left: 0;
}

.project-list-grid {
    margin: 0;
}

.filter-panel-left {
    height: 47em;
    background-color: #fafafa;
    /* background-color: #e6e6e6; */
    border-right: 1px solid #d2d2d2;
    transition: all 0.5s ease;
    width: 20%;
    max-width: 189px !important;
}

.filter-panel-left.panel-move {
    width: 40px;
    white-space: nowrap;
}

.filter-panel-left .ms-Label {
    white-space: nowrap;
}

.filter-panel-left .ms-TooltipHost {
    display: none;
}

.ms-Grid-col.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ms-Grid-col.padding-left-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.grid-righter {
    flex-grow: 1;
    border-right: 1px solid #d2d2d2;
}

/* ------------------------------- */
/* .grid-righter.column-filter-activated .wrapper-46 {
    height: calc(100vh - 269px);
} */
.ms-Grid .grid-righter .ms-DetailsList-headerWrapper,
.grid-righter .ms-DetailsList-contentWrapper {
    position: static;
    transition: all linear 0.2s;
}

.grid-righter.column-filter-activated .ms-DetailsList-headerWrapper,
.grid-righter.column-filter-activated .footer-as-filter {
    position: absolute;
    top: 0;
}

.grid-righter.column-filter-activated .footer-as-filter {
    top: 47px;
}

.grid-righter.column-filter-activated .ms-DetailsList-contentWrapper {
    padding-top: 103px;
}

.ms-Grid .ms-DetailsRow-cell {
    color: #494847;
    font-size: 14px;
}

.ms-Grid .ms-DetailsRow.is-selected .ms-DetailsRow-cell {
    font-weight: bold;
}

/* ------------------------------- */

.chkbox-position {
    margin-top: 33px;
}

.back-arrow,
.fwd-arrow {
    cursor: pointer;
    font-size: 18px;
}

.fwd-arrow {
    display: none;
}

.filter-data {
    margin-top: 0.3rem;
}

.filter-data label {
    padding-bottom: 0.8rem;
}

.filter-data .ms-SearchBox {
    width: 99%;
}

.filter-option-list {
    margin-top: 1.5rem;
    white-space: nowrap;
}

.filter-option-list label {
    padding-bottom: 0.8rem;
}

.filter-option-list button,
.filter-option-list a {
    display: flex;
    align-items: center;
}

.filter-option-list button i,
.filter-option-list a i {
    padding-right: 0.5rem;
}

.filter-option-list button:hover,
.filter-option-list a:hover {
    text-decoration: none;
}

.filter-option-list button i.root-143,
.filter-option-list a i.root-143 {
    font-size: 1.1rem;
    margin-left: -2px;
}

.filter-option-list a span {
    font-size: 14px;
    font-weight: 600;
}

.filter-option-list a i {
    font-size: 15px;
}

.project-detail-list .proj-detail-block {
    width: 100%;
    margin-top: 15px;
}

.project-detail-list .proj-detail-block a {
    width: 100%;
    border-bottom: 1px solid #bab8b7;
    display: block;
}

.project-detail-list .proj-detail-block a,
.project-detail-list .proj-detail-block a label {
    font-size: 1.1rem;
    cursor: default;
}

.project-detail-list .proj-detail-block a:hover,
.project-detail-list .proj-detail-block a:hover label {
    text-decoration: none;
}

.project-detail-list .proj-detail-block .proj-detail-content {
    padding-top: 0.5rem;
}

.project-detail-list .proj-detail-block .proj-detail-content.inner {
    /* width: 80%; */
    width: calc(100% - 20px);
}

.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a,
.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a
label {
    opacity: 0.8;
    font-size: 1rem;
}

.ms-Stack.css-168 {
    scroll-behavior: smooth;
}

.bookmark-panel.parent,
.bookmark-panel-responsive {
    width: 210px;
    height: 20rem;
    position: absolute;
    top: 100px;
    right: -1px;
    background-color: #ffffff;
    border: 1px solid #bab8b7;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
    transition: all 0.2s linear;
}

.bookmark-panel.parent {
    width: calc(100% - 80%);
    /*// height: calc(100% - 3.75rem);*/
    height: calc(100vh - 174px);
    top: 45px;
    z-index: 1;
    border: 1px solid #d2d2d2;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
}

.bookmark-panel-responsive {
    top: 46px;
    right: 17px;
    border-top: 0;
}

.bookmark-panel.parent a {
    font-size: 14px !important;
    display: block;
    padding: 1px 0;
}

.bookmark-panel.parent label {
    padding: 1px 0;
}

.bookmark-panel.parent .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 0;
    display: block;
    background-color: #ffffff;
}

.bookmark-panel.parent .inner-container {
    overflow: auto;
    margin-top: 0;
    padding-top: 0;
}

.bookmark-panel.parent i.ms-Icon--ChromeClose {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    display: none;
}

.bookmark-panel-responsive {
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 8px 8px 5px;
    display: none;
}

.bookmark-panel-responsive i {
    font-size: 22px;
    /* color: #ee7423; */
}

.bookmark-panel-responsive i:hover {
    color: #854114;
}

.bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
    display: none;
    font-size: 14px;
    opacity: 0.5;
}

.bookmark-panel .inner-container a {
    border-left: 4px solid #ffffff;
    padding-left: 10px;
}

.bookmark-panel .inner-container label.selected,
.bookmark-panel .inner-container a.selected {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
}

.bookmark-panel .inner-container label.selected a {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
    color: #854114;
}

.bookmark-panel .inner-container label.selected {
    border-left: 0;
}

.ms-Panel .ms-Panel-content {
    padding: 0;
}

.ms-Panel .project-list-header {
    padding: 0 0.5rem;
    min-height: auto;
}

.ms-Panel .ms-Panel-contentInner .ms-Panel-header {
    position: absolute;
    top: 0;
    width: 80%;
    margin-top: 8px;
}

.custom-detail-panel .ms-Panel-main {
    width: 60%;
}

.ms-Panel .ms-Panel-scrollableContent {
    overflow: hidden !important;
    position: relative;
    height: 100%;
}

/* const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
} */

.filter-panel-left.panel-move .ms-Label {
    visibility: hidden;
    white-space: nowrap;
}

.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    border-width: 0;
    background-color: transparent;
}

.filter-panel-left.panel-move .ms-SearchBox-field {
    visibility: hidden;
}

.filter-panel-left.panel-move .ms-SearchBox-iconContainer {
    text-align: left;
}

.filter-panel-left.panel-move .back-arrow {
    display: none;
}

.filter-panel-left.panel-move .fwd-arrow {
    display: block;
}

.filter-panel-left.panel-move .filter-option-list button span,
.filter-panel-left.panel-move .filter-option-list a span {
    visibility: hidden;
}

.filter-panel-left.panel-move .filter-option-list button i,
.filter-panel-left.panel-move .filter-option-list a i,
.filter-panel-left.panel-move .ms-SearchBox-icon,
.filter-panel-left .search-icon-only {
    font-size: 20px;
    padding-right: 0;
    padding-left: 2px;
    transition: all 0.2s linear;
    font-weight: normal;
}

.filter-panel-left.panel-move .ms-SearchBox-icon {
    padding-left: 0;
    margin-left: -2px;
}

.filter-panel-left.panel-move .filter-option-list button i.root-143,
.filter-panel-left.panel-move .filter-option-list a i.root-143 {
    font-size: 22px;
}

.filter-panel-left.panel-move .filter-option-list .ms-Stack button {
    margin-bottom: 15px;
}

.filter-panel-left.panel-move
.ms-SearchBox.txt-filter-1
.ms-SearchBox-iconContainer {
    cursor: pointer;
}

.filter-panel-left.panel-move .ms-TooltipHost {
    display: inline;
}

.filter-panel-left.panel-move .filter-icon,
.filter-panel-left .search-icon-only,
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    display: none;
}

.filter-panel-left.panel-move .search-icon-only {
    display: block;
}

.filter-panel-left .search-icon-only {
    /* color: #ee7423; */
    margin-top: 0.5rem;
    cursor: pointer;
}

.filter-panel-left.panel-move .search-icon-only {
    font-size: 22px;
}

.padding-set-top {
    padding-top: 1rem;
}

/* Fabric ICON */
.filter-panel-left i,
.project-detail-list i {
    font-family: FabricMDL2Icons;
}

.filter-panel-left i.ms-SearchBox-icon {
    text-indent: -200000px;
    position: relative;
    font-size: 18px;
    margin-top: -2px;
}

.filter-panel-left i.ms-SearchBox-icon:before {
    content: '\E721';
    position: absolute;
    left: 5px;
    top: 2px;
    text-indent: 0;
}

i.ms-Icon--POISolid:before {
    content: '\F2D1';
}

i.ms-Icon--Waffle:before {
    content: '\ED89';
}

i.ms-Icon--FolderHorizontal:before {
    content: '\F12B';
}

i.ms-Icon--SelectAll:before {
    content: '\E8A9';
}

i.ms-Icon--GroupedList:before {
    content: '\EF74';
}

i.ms-Icon--Search:before {
    content: '\E721';
}

i.ms-Icon--GotoToday:before {
    content: '\E8D1';
}

i.ms-Icon--CalendarWeek:before {
    content: '\E8C0';
}

i.ms-Icon--CalendarReply:before {
    content: '\E8F5';
}

i.ms-Icon--FavoriteList:before {
    content: '\E728';
}

i.ms-Icon--Completed:before {
    content: '\E930';
}

i.ms-Icon--Back:before {
    content: '\E700';
}

i.ms-Icon--Forward:before {
    content: '\E700';
}

i.ms-Icon.ms-Icon--SearchBookmark:before {
    content: '\F5B8';
}

i.ms-Icon.ms-Icon--ChromeClose:before {
    content: '\E8BB';
}

/* ------------ */
.common-commandbar i,
.overflow-control i,
.ms-ContextualMenu i {
    font-family: FabricMDL2Icons;
    font-style: normal;
}

.common-commandbar [data-automation-id='addNewButton'] i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addNewButton'] i:before {
    content: '\E710';
}

.common-commandbar
[data-automation-id='addUploadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addUploadButton'] i:before {
    content: '\E898';
}

.common-commandbar
[data-automation-id='addShareButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addShareButton'] i:before {
    content: '\E72D';
}

.common-commandbar
[data-automation-id='addDownloadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addDownloadButton'] i:before {
    content: '\E896';
}

.common-commandbar
[data-automation-id='addMoveButton']
i.ms-Button-icon:before {
    content: '\E8DE';
}

.common-commandbar
[data-automation-id='addCopyButton']
i.ms-Button-icon:before {
    content: '\E8C8';
}

.common-commandbar [data-automation-id='renameButton'] i.ms-Button-icon:before {
    content: '\E70F';
}

.common-commandbar
[data-automation-id='addSortButton']
i.ms-Button-icon:before {
    content: '\E9D0';
}

.common-commandbar
[data-automation-id='addTilesButton']
i.ms-Button-icon:before {
    content: '\ECA5';
}

.common-commandbar
[data-automation-id='addInfoButton']
i.ms-Button-icon:before {
    content: '\E946';
}

/* ------------ */
.ms-ContextualMenu [data-automation-id='newEmailButton'] i:before {
    content: '\E715';
}

.ms-ContextualMenu
[data-automation-id='addCalendarButton']
i.ms-Icon-placeHolder:before {
    content: '\E787';
}

.ms-ContextualMenu
[data-automation-id='addMoveButton']
i.ms-Icon-placeHolder:before {
    content: '\E8DE';
}

.ms-ContextualMenu
[data-automation-id='addCopyButton']
i.ms-Icon-placeHolder:before {
    content: '\E8C8';
}

.ms-ContextualMenu
[data-automation-id='renameButton']
i.ms-Icon-placeHolder:before {
    content: '\E70F';
}

/* ------------ */
.overflow-control
.ms-OverflowSet-item:first-child
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E710';
}

.overflow-control
.ms-OverflowSet-item:nth-child(2)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E898';
}

.overflow-control
.ms-OverflowSet-item:nth-child(3)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E72D';
}

/* ------------ */
.common-commandbar {
    width: 100%;
}

/* .common-commandbar .ms-Button:hover,
.overflow-control .ms-Button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 31, 30);
} */
.overflow-control .ms-Button {
    padding: 14px 10px;
}

.overflow-control,
.accordian-filter-panel .ms-Button {
    position: absolute;
    top: 0;
    right: 2px;
}

.ms-Pivot.ms-Pivot--large.ms-Pivot--tabs .ms-Button {
    display: none !important;
}

.accordian-filter-panel .ms-Button {
    right: auto;
    margin-top: 6px;
}

.accordian-container {
    position: static;
    padding-top: 50px;
}

.common-commandbar .ms-Button-menuIcon,
.ms-OverflowSet i[data-icon-name='ChevronDown'],
.accordian-filter-panel i[data-icon-name='ChevronDown'],
.footer-as-filter i[data-icon-name='ChevronDown'],
.ms-OverflowSet i[data-icon-name='More'],
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon,
.ms-Panel-main i[data-icon-name='Cancel'],
.ms-DetailsList-headerWrapper i[data-icon-name='Page'],
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp'] {
    text-indent: -20000000px;
    position: relative;
    color: rgb(243, 242, 241);
}

.common-commandbar i[data-icon-name='More']:before,
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before,
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before,
.ms-Panel-main i[data-icon-name='Cancel']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\E712';
    position: absolute;
    top: 0;
    left: -9px;
    text-indent: 0;
    color: rgb(163, 162, 160);
}

.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E70D';
    left: -5px;
}

.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before {
    content: '\E70D';
    left: -14px;
}

.common-commandbar [data-automation-id='addNewButton'] {
    padding-right: 10px;
}

i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before {
    content: '\E76C';
}

.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -6px;
}

.common-commandbar .ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -9px;
}

.ms-Panel-main i[data-icon-name='Cancel']:before {
    content: '\E8BB';
}

.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before {
    content: '\E7C3';
    left: 0;
}

.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\EE68';
    left: 4px;
}

/* ------------ */
/* ------------ */
@media only screen and (max-width: 1440px) {
    .filter-panel-left .back-arrow,
    .filter-panel-left.panel-move .back-arrow,
    .filter-panel-left .fwd-arrow,
    .filter-panel-left.panel-move .fwd-arrow {
        visibility: hidden;
    }

    .filter-panel-left {
        width: 40px;
        white-space: nowrap;
    }

    .filter-panel-left .ms-Label {
        visibility: hidden;
        white-space: nowrap;
    }

    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left.filter-focus,
    .filter-panel-left:hover {
        width: 30%;
        max-width: 189px !important;
    }

    .filter-panel-left:hover .ms-Label,
    .filter-panel-left:hover .ms-SearchBox-field,
    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left .ms-SearchBox.txt-filter-1 {
        border-width: 0;
        background-color: transparent;
    }

    .filter-panel-left .ms-SearchBox-field {
        visibility: hidden;
    }

    .filter-panel-left .ms-SearchBox-iconContainer {
        text-align: left;
    }

    .filter-panel-left:hover .ms-SearchBox.txt-filter-1,
    .filter-panel-left.filter-focus .ms-SearchBox.txt-filter-1 {
        border-width: 1px;
        background-color: #ffffff;
    }

    .filter-panel-left .filter-option-list button span,
    .filter-panel-left .filter-option-list a span {
        visibility: hidden;
    }

    .filter-panel-left:hover .filter-option-list button span,
    .filter-panel-left.filter-focus .filter-option-list button span,
    .filter-panel-left:hover .filter-option-list a span,
    .filter-panel-left.filter-focus .filter-option-list a span {
        visibility: visible;
    }

    .filter-panel-left .filter-option-list button i,
    .filter-panel-left .filter-option-list a i,
    .filter-panel-left .ms-SearchBox-icon {
        font-size: 20px;
        padding-right: 0;
        padding-left: 2px;
        transition: all 0.2s linear;
        font-weight: normal;
    }

    .filter-panel-left .ms-SearchBox-icon {
        padding-left: 0;
        margin-left: -2px;
    }

    .filter-panel-left .filter-option-list button i.root-143,
    .filter-panel-left .filter-option-list a i.root-143 {
        font-size: 22px;
    }

    .filter-panel-left .filter-option-list .ms-Stack button {
        margin-bottom: 15px;
    }

    .filter-panel-left:hover .filter-option-list button i,
    .filter-panel-left.filter-focus .filter-option-list button i,
    .filter-panel-left:hover .filter-option-list a i,
    .filter-panel-left.filter-focus .filter-option-list a i {
        font-size: 14px;
        padding-right: 0.5rem;
        padding-left: auto;
    }

    .filter-panel-left:hover .filter-option-list button i.root-143,
    .filter-panel-left.filter-focus .filter-option-list button i.root-143,
    .filter-panel-left:hover .filter-option-list a i.root-143,
    .filter-panel-left.filter-focus .filter-option-list a i.root-143 {
        font-size: 16px;
    }

    .filter-panel-left:hover .filter-option-list .ms-Stack button,
    .filter-panel-left.filter-focus .filter-option-list .ms-Stack button {
        margin-bottom: auto;
    }

    .filter-panel-left:hover .ms-SearchBox-icon,
    .filter-panel-left.filter-focus .ms-SearchBox-icon {
        font-size: 16px;
        padding-left: auto;
        margin-left: 8px;
    }

    .filter-panel-left .ms-TooltipHost,
    .filter-panel-left:hover .ms-TooltipHost {
        display: none;
    }

    .filter-panel-left .filter-icon,
    .filter-panel-left:hover .filter-icon {
        display: block;
    }

    .filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
        display: block;
    }

    .filter-panel-left i.ms-SearchBox-icon:before {
        left: 0;
    }

    .project-detail-list .proj-detail-block .proj-detail-content.inner {
        width: calc(100% - 20px);
    }

    .bookmark-panel.parent {
        display: none;
    }

    .bookmark-panel-responsive,
    .bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
        display: block;
    }

    .bookmark-panel i,
    .bookmark-panel.parent.panel-on {
        display: inline-flex;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
    .filter-panel-left {
        width: 25%;
    }

    .project-detail-list.inner-container {
        width: 75%;
    }

    .bookmark-panel.parent {
        width: calc(100% - 75%);
    }
}

@media only screen and (max-width: 1000px) {
    .hidden-M {
        display: none !important;
    }
}

@media only screen and (max-width: 1279px) {
    .hidden-L {
        display: none !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .hidden-XL {
        display: none !important;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
    .project-detail-list.inner-container {
        width: 100%;
    }

    .ms-Grid-col.ms-lg6.grid-content-left {
        width: 52%;
    }

    .ms-Grid-col.ms-lg6.grid-content-right {
        width: 48%;
    }
}

@media only screen and (max-width: 1100px) {
    .ms-Grid-col.grid-content-left {
        width: 100%;
    }

    .ms-Grid-col.grid-content-right {
        display: none;
    }

    .bookmark-panel.parent {
        top: -1px;
        height: 100%;
    }

    .bookmark-panel-responsive {
        top: 0;
        right: 17px;
    }

    .grid-righter {
        border-right: 0;
    }
}

@media only screen and (max-width: 1440px) {
    .bookmark-panel-responsive {
        width: auto;
    }

    .bookmark-panel.parent {
        width: 220px;
    }

    .filter-panel-left .ms-TooltipHost .search-icon-only {
        display: none;
    }

    .filter-panel-left i.ms-SearchBox-icon {
        font-size: 22px;
        margin-left: -3px;
    }
}

.window-width-indicator {
    position: fixed;
    top: 6px;
    right: 15px;
    color: white;
    font-size: 12px;
    font-weight: normal;
}

.ms-DetailsHeader {
    padding-top: 0 !important;
}

.ms-DetailsList {
    min-height: 100px;
}

.qrListPane {
    height: 100% !important;
}

.is-disabled span i {
    color: #999999 !important;
}

.is-disabled label {
    color: #000000 !important;
}

.ms-Dialog-main {
    overflow: hidden;
}

.reactSelect__placeholder {
    top: 49% !important;
}

.reactSelect__indicator-separator {
    display: none !important;
}

.project-detail-list.inner-container.cpc {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.cab {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.withoutTitlePane {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 185px) !important;
}

.title-pane {
    /* background-color: #f4681d; */
    color: white;
    border-left: 1px solid #ddd;
}

.ck-editor__editable {
    min-height: 180px !important;
}

/* .ms-Suggestions .ms-SearchMore-button {
  display: none !important;
} */

.breadcrumb-bar {
    border: 1px solid rgb(96, 94, 92);
    height: 30px;
    margin-top: 0;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .resource-family {
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .resource-family {
        width: 90%;
    }
}

@media only screen and (min-width: 1281px) {
    .resource-family {
        width: 94%;
    }
}
