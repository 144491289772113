/* MAKE LEFT COLUMN FIXEZ */
.dp tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: 'white';
}

.dp table tbody:nth-of-type(1) tr:nth-of-type(1) td {
  border-top: none !important;
}

.dp table thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
  padding: 1px 0;
}