.document-pane-body {
    height: 80vh;
    overflow: overlay;
    width: 75vh;
}

.full-width {
    width: 100%;
}

.issue .full-width{
    margin-top: 20px
}

.half-width {
    width: 49%
}

.add-new-task-form {
    height: 100%;
    width: 99%;
    overflow: auto
}

.task-form {
    width: auto;
}
