.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left {
    display: flex;
    justify-content: left;
}

.title-text {
    font-size: large;
    margin-bottom: 20px;
}

.marginTop {
    margin-top: 10px;
}

.borderRight {
    border-right: 0.1px solid #eaecee;
}

.borderBottom {
    border-Bottom: 0.1px solid #eaecee;
    width: 100vh
}

.fullHeight {
    height: 100vh;
    display: flex;
}

.fullCol {
    height: 55em;
    flex: 1;
}

.timeclockicon {
    color: aquamarine;
}

