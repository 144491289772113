.ganttVerticalContainer {
    overflow: hidden;
    font-size: 0;
    margin: 0;
    padding: 0;
}

.horizontalContainer {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    position: relative;
}


