.wrapper {
    height: calc(100vh - 14vh) !important;
    position: relative;
}

.selected {
    font-weight: bold;
    color: #854114 !important;
}

.hr {
    margin-bottom: 15px;
    border-top: 1px solid #d2d2d2;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 49%;
}

.icon {
    display: inline-block;
    font-family: 'FabricMDL2Icons', F, sans-serif;
    font-style: normal;
    font-weight: 400;
    speak: none;
    margin: 4px 8px;
    cursor: pointer;
}

.display-none {
    display: none;
}

.collapsed {
    transition: none !important;
    min-width: 32px !important;
}

.expand {
    transition: none !important;
    min-width: 255px !important;
}
