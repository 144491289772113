.brand {
    /* background-color: var(--primary); */
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.brandicon {
    margin-left: 0px;
    size: 40px;
    color: white;
    font-size: 30px;
    margin-right: 5px;
}

.settingicon {
    margin-left: 0px;
    size: 30px;
    color: white;
    font-size: 20px;
    margin-right: 15px;
}

.meNavBar {
    margin-left: auto;
    size: 30px;
    color: white;
    font-size: 20px;
    float: right;
    margin-right: 15px;
}

.brandName {
    font-size: 18px;
    color: white;
    margin-left: 10px;
    font-weight: 600;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

/* global color codes */
:root {
    /* --primary: #f4681d; */
    --blue: #4679bd;
    --grey: #ddd;
    --W200: 200px;
    --Lft: left;
}

