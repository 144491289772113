/*.ms-DetailsList{*/
/*height: calc(100vh);*/
/*}*/

.header-bar {
  background-color: #ee7423;
  border-bottom: 1px solid #854114;
  color: white;
  align-items: center;
}
.header-bar a {
  color: white;
  display: flex;
}
.header-bar a:hover {
  text-decoration: none;
}
.header-bar i {
  font-size: 2rem;
  font-weight: bold;
}
.header-bar i:hover {
  text-decoration: none;
}
.header-bar label {
  color: white;
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 0.2rem !important;
}
.recent-project-bar {
  background-color: #e6e6e6;
  border-bottom: 1px solid #d2d2d2;
  white-space: nowrap;
}
.recent-project-bar a {
  font-size: 0.8rem;
  color: #323130;
  display: flex;
}
.recent-project-bar i {
  font-size: 1rem;
  line-height: 1.2rem;
  padding-right: 0.2rem;
  opacity: 0.6;
}
.recent-project-bar i.root-199 {
  opacity: 0.9;
  padding-left: 2px;
}
.recent-project-bar .gap-control {
  margin-left: 20px !important;
}
.content-holder {
  width: calc(100% - 30px);
  border: 1px solid #bab8b7;
  margin-top: 15px !important;
}
.content-holder-inner {
  margin-top: 15px !important;
  overflow: hidden;
}
.holder-inner {
  border: 1px solid #d2d2d2;
}
.title-bar-1 {
  border-bottom: 1px solid #bab8b7;
  width: 100%;
  padding: 0.5rem;
}
.title-bar-1 a {
  line-height: 0;
  display: flex;
  align-items: center;
}
.title-bar-1 a:hover,
.title-bar-1 a:hover i {
  text-decoration: none;
}
.title-bar-1 i {
  font-size: 1.4rem;
  line-height: 1.2rem;
  color: #323130;
  padding-right: 0.5rem;
}
.title-bar-1 label {
  padding: 0;
  cursor: pointer;
}
/* match with the current app */
.title-bar-1.title-bar-1.title-bar-inner {
  background-color: #fafafa;
  border-bottom: 1px solid #d2d2d2;
}
.title-bar-1.title-bar-inner,
.title-bar-1.title-bar-inner label {
  cursor: default;
}
.title-bar-1.title-bar-inner label {
  margin-left: 0;
}
.inner-container {
  padding: 0.5rem;
}
.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: 80vh;
}
.project-detail-list.full-inner-container {
  padding: 0 0 1rem 1rem;
  width: calc(100% - 40px);
  min-height: calc(100vh - 218px) !important;
}
.project-list-header {
  padding: 0.5rem;
  min-height: 46px;
  border-bottom: 1px solid #d2d2d2;
}
.project-list-header.right-panel {
  padding: 0 0.5rem 0 0;
}
/* .project-list-header button,
.ms-CommandBar,
.ms-CommandBar .ms-Button.ms-Button--commandBar {
    background-color: #ffffff !important;
}
.project-list-header button.ms-Button--default {
    background-color: #ffffff !important;
} */
.ms-CommandBar {
  padding-right: 0;
  padding-left: 0;
}
.project-list-grid {
  margin: 0;
}
.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 189px !important;
  min-width: 189px;
  position: sticky;
  left: 0;
  z-index: 2;
}
.filter-panel-left.panel-move {
  width: 40px;
  min-width: 40px;
  white-space: nowrap;
}
.filter-panel-left .ms-Label {
  white-space: nowrap;
}
.filter-panel-left .ms-TooltipHost {
  display: none;
}

.ms-Grid-col.padding-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ms-Grid-col.padding-left-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.grid-righter {
  flex-grow: 1;
  border-right: 1px solid #d2d2d2;
}

/* ------------------------------- */
/* .grid-righter.column-filter-activated .wrapper-46 {
    height: calc(100vh - 269px);
} */
.ms-Grid .grid-righter .ms-DetailsList-headerWrapper,
.grid-righter .ms-DetailsList-contentWrapper {
  position: static;
  transition: all linear 0.2s;
}
.grid-righter.column-filter-activated .ms-DetailsList-headerWrapper,
.grid-righter.column-filter-activated .footer-as-filter {
  position: absolute;
  top: 0;
}
.grid-righter.column-filter-activated .footer-as-filter {
  top: 47px;
}
.grid-righter.column-filter-activated .ms-DetailsList-contentWrapper {
  padding-top: 103px;
}
.ms-Grid .ms-DetailsRow-cell {
  color: #494847;
  font-size: 14px;
}
.ms-Grid .ms-DetailsRow.is-selected .ms-DetailsRow-cell {
  font-weight: bold;
}
/* ------------------------------- */

.chkbox-position {
  margin-top: 33px;
}

.back-arrow,
.fwd-arrow {
  cursor: pointer;
  font-size: 18px;
}
.fwd-arrow {
  display: none;
}
.filter-data {
  margin-top: 0.3rem;
}
.filter-data label {
  padding-bottom: 0.8rem;
}
.filter-data .ms-SearchBox {
  width: 99%;
}

.filter-option-list {
  margin-top: 1.5rem;
  white-space: nowrap;
}
.filter-option-list label {
  padding-bottom: 0.8rem;
}
.filter-option-list button,
.filter-option-list a {
  display: flex;
  align-items: center;
}
.filter-option-list button i,
.filter-option-list a i {
  padding-right: 0.5rem;
}
.filter-option-list button:hover,
.filter-option-list a:hover {
  text-decoration: none;
}
.filter-option-list button i.root-143,
.filter-option-list a i.root-143 {
  font-size: 1.1rem;
  margin-left: -2px;
}
.filter-option-list a span {
  font-size: 14px;
  font-weight: 600;
}
.filter-option-list a i {
  font-size: 15px;
}

.project-detail-list .proj-detail-block {
  width: 100%;
  margin-top: 15px;
}
.project-detail-list .proj-detail-block a {
  width: 100%;
  border-bottom: 1px solid #bab8b7;
  display: block;
}
.project-detail-list .proj-detail-block a,
.project-detail-list .proj-detail-block a label {
  font-size: 1.1rem;
  cursor: default;
}
.project-detail-list .proj-detail-block a:hover,
.project-detail-list .proj-detail-block a:hover label {
  text-decoration: none;
}
.project-detail-list .proj-detail-block .proj-detail-content {
  padding-top: 0.5rem;
}
.project-detail-list .proj-detail-block .proj-detail-content.inner {
  /* width: 80%; */
  width: calc(100% - 20px);
}
.project-detail-list
  .proj-detail-block
  .proj-detail-content.group-on
  .proj-detail-block
  a,
.project-detail-list
  .proj-detail-block
  .proj-detail-content.group-on
  .proj-detail-block
  a
  label {
  opacity: 0.8;
  font-size: 1rem;
}

.ms-Stack.css-168 {
  scroll-behavior: smooth;
}
.bookmark-panel.parent,
.bookmark-panel-responsive {
  width: 210px;
  height: 20rem;
  position: absolute;
  top: 100px;
  right: -1px;
  background-color: #ffffff;
  border: 1px solid #bab8b7;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
}
.bookmark-panel.parent {
  width: calc(100% - 80%);
  /*// height: calc(100% - 3.75rem);*/
  height: calc(100vh - 174px);
  top: 43px;
  z-index: 1;
  border: 1px solid #d2d2d2;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.bookmark-panel-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.bookmark-panel.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.bookmark-panel.parent label {
  padding: 1px 0;
}
.bookmark-panel.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.bookmark-panel.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.bookmark-panel.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel.parent.collapse i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.bookmark-panel-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.bookmark-panel-responsive i:hover {
  color: #854114;
}
.bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.bookmark-panel .inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel .inner-container label.selected,
.bookmark-panel .inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel .inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel .inner-container label.selected {
  border-left: 0;
}
.ms-Panel .ms-Panel-content {
  padding: 0;
}
.ms-Panel .project-list-header {
  padding: 0 0.5rem;
  min-height: auto;
}
.ms-Panel .ms-Panel-contentInner .ms-Panel-header {
  position: absolute;
  top: 0;
  width: 80%;
  margin-top: 8px;
}
.custom-detail-panel .ms-Panel-main {
  width: 60%;
}
.ms-Panel .ms-Panel-scrollableContent {
  overflow: hidden !important;
  position: relative;
  height: 100%;
}

/* const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
} */

.filter-panel-left.panel-move .ms-Label {
  visibility: hidden;
  white-space: nowrap;
}
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
  border-width: 0;
  background-color: transparent;
}
.filter-panel-left.panel-move .ms-SearchBox-field {
  visibility: hidden;
}
.filter-panel-left.panel-move .ms-SearchBox-iconContainer {
  text-align: left;
}
.filter-panel-left.panel-move .back-arrow {
  display: none;
}
.filter-panel-left.panel-move .fwd-arrow {
  display: block;
}
.filter-panel-left.panel-move .filter-option-list button span,
.filter-panel-left.panel-move .filter-option-list a span {
  visibility: hidden;
}
.filter-panel-left.panel-move .filter-option-list button i,
.filter-panel-left.panel-move .filter-option-list a i,
.filter-panel-left.panel-move .ms-SearchBox-icon,
.filter-panel-left .search-icon-only {
  font-size: 20px;
  padding-right: 0;
  padding-left: 2px;
  transition: all 0.2s linear;
  font-weight: normal;
}
.filter-panel-left.panel-move .ms-SearchBox-icon {
  padding-left: 0;
  margin-left: -2px;
}
.filter-panel-left.panel-move .filter-option-list button i.root-143,
.filter-panel-left.panel-move .filter-option-list a i.root-143 {
  font-size: 22px;
}
.filter-panel-left.panel-move .filter-option-list .ms-Stack button {
  margin-bottom: 15px;
}
.filter-panel-left.panel-move
  .ms-SearchBox.txt-filter-1
  .ms-SearchBox-iconContainer {
  cursor: pointer;
}
.filter-panel-left.panel-move .ms-TooltipHost {
  display: inline;
}
.filter-panel-left.panel-move .filter-icon,
.filter-panel-left .search-icon-only,
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
  display: none;
}
.filter-panel-left.panel-move .search-icon-only {
  display: block;
}
.filter-panel-left .search-icon-only {
  /* color: #ee7423; */
  margin-top: 0.5rem;
  cursor: pointer;
}
.filter-panel-left.panel-move .search-icon-only {
  font-size: 22px;
}
.padding-set-top {
  padding-top: 1rem;
}

/* Fabric ICON */
.filter-panel-left i,
.project-detail-list i {
  font-family: FabricMDL2Icons;
}
.filter-panel-left i.ms-SearchBox-icon {
  text-indent: -200000px;
  position: relative;
  font-size: 18px;
  margin-top: -2px;
}
.filter-panel-left i.ms-SearchBox-icon:before {
  content: '\E721';
  position: absolute;
  left: 5px;
  top: 2px;
  text-indent: 0;
}
i.ms-Icon--POISolid:before {
  content: '\F2D1';
}
i.ms-Icon--Waffle:before {
  content: '\ED89';
}
i.ms-Icon--FolderHorizontal:before {
  content: '\F12B';
}
i.ms-Icon--SelectAll:before {
  content: '\E8A9';
}
i.ms-Icon--GroupedList:before {
  content: '\EF74';
}
i.ms-Icon--Search:before {
  content: '\E721';
}
i.ms-Icon--GotoToday:before {
  content: '\E8D1';
}
i.ms-Icon--CalendarWeek:before {
  content: '\E8C0';
}
i.ms-Icon--CalendarReply:before {
  content: '\E8F5';
}
i.ms-Icon--FavoriteList:before {
  content: '\E728';
}
i.ms-Icon--Completed:before {
  content: '\E930';
}
i.ms-Icon--Back:before {
  content: '\E700';
}
i.ms-Icon--Forward:before {
  content: '\E700';
}
i.ms-Icon.ms-Icon--SearchBookmark:before {
  content: '\F5B8';
}
i.ms-Icon.ms-Icon--ChromeClose:before {
  content: '\E8BB';
}
i.ms-Icon--Flow:before {
  content: '\EF90';
}
i.ms-Icon--Archive:before {
  content: '\F03F';
}
i.ms-Icon--Home:before {
  content: '\E80F';
}

/* ------------ */
.common-commandbar i,
.overflow-control i,
.ms-ContextualMenu i {
  font-family: FabricMDL2Icons;
  font-style: normal;
}
.common-commandbar [data-automation-id='addNewButton'] i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addNewButton'] i:before {
  content: '\E710';
}
.common-commandbar
  [data-automation-id='addUploadButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addUploadButton'] i:before {
  content: '\E898';
}
.common-commandbar
  [data-automation-id='addShareButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addShareButton'] i:before {
  content: '\E72D';
}
.common-commandbar
  [data-automation-id='addDownloadButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addDownloadButton'] i:before {
  content: '\E896';
}
.common-commandbar
  [data-automation-id='addMoveButton']
  i.ms-Button-icon:before {
  content: '\E8DE';
}
.common-commandbar
  [data-automation-id='addCopyButton']
  i.ms-Button-icon:before {
  content: '\E8C8';
}
.common-commandbar [data-automation-id='renameButton'] i.ms-Button-icon:before {
  content: '\E70F';
}
.common-commandbar
  [data-automation-id='addSortButton']
  i.ms-Button-icon:before {
  content: '\E9D0';
}
.common-commandbar
  [data-automation-id='addTilesButton']
  i.ms-Button-icon:before {
  content: '\ECA5';
}
.common-commandbar
  [data-automation-id='addInfoButton']
  i.ms-Button-icon:before {
  content: '\E946';
}

/* ------------ */
.ms-ContextualMenu [data-automation-id='newEmailButton'] i:before {
  content: '\E715';
}
.ms-ContextualMenu
  [data-automation-id='addCalendarButton']
  i.ms-Icon-placeHolder:before {
  content: '\E787';
}
.ms-ContextualMenu
  [data-automation-id='addMoveButton']
  i.ms-Icon-placeHolder:before {
  content: '\E8DE';
}
.ms-ContextualMenu
  [data-automation-id='addCopyButton']
  i.ms-Icon-placeHolder:before {
  content: '\E8C8';
}
.ms-ContextualMenu
  [data-automation-id='renameButton']
  i.ms-Icon-placeHolder:before {
  content: '\E70F';
}

/* ------------ */
.overflow-control
  .ms-OverflowSet-item:first-child
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E710';
}
.overflow-control
  .ms-OverflowSet-item:nth-child(2)
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E898';
}
.overflow-control
  .ms-OverflowSet-item:nth-child(3)
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E72D';
}

/* ------------ */
.common-commandbar {
  width: 100%;
}
/* .common-commandbar .ms-Button:hover,
.overflow-control .ms-Button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 31, 30);
} */
.overflow-control .ms-Button {
  padding: 14px 10px;
}
.overflow-control,
.accordian-filter-panel .ms-Button {
  position: absolute;
  top: 0;
  right: 2px;
}
.ms-Pivot.ms-Pivot--large.ms-Pivot--tabs .ms-Button {
  display: none !important;
}
.accordian-filter-panel .ms-Button {
  right: auto;
  margin-top: 6px;
}
.accordian-container {
  position: static;
  padding-top: 50px;
}

.common-commandbar .ms-Button-menuIcon,
.ms-OverflowSet i[data-icon-name='ChevronDown'],
.accordian-filter-panel i[data-icon-name='ChevronDown'],
.footer-as-filter i[data-icon-name='ChevronDown'],
.ms-OverflowSet i[data-icon-name='More'],
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon,
.ms-Panel-main i[data-icon-name='Cancel'],
.ms-DetailsList-headerWrapper i[data-icon-name='Page'],
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp'] {
  text-indent: -20000000px;
  position: relative;
  color: rgb(243, 242, 241);
}
.common-commandbar i[data-icon-name='More']:before,
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before,
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before,
.ms-Panel-main i[data-icon-name='Cancel']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
  content: '\E712';
  position: absolute;
  top: 0;
  left: -9px;
  text-indent: 0;
  color: rgb(163, 162, 160);
}
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E70D';
  left: -5px;
}
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before {
  content: '\E70D';
  left: -14px;
}
.common-commandbar [data-automation-id='addNewButton'] {
  padding-right: 10px;
}
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before {
  content: '\E76C';
}
.ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E712';
  left: -6px;
}
.common-commandbar .ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E712';
  left: -9px;
}
.ms-Panel-main i[data-icon-name='Cancel']:before {
  content: '\E8BB';
}
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before {
  content: '\E7C3';
  left: 0;
}
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
  content: '\EE68';
  left: 4px;
}

/* ------------ */
/* ------------ */
@media only screen and (max-width: 1440px) {
  .filter-panel-left .back-arrow,
  .filter-panel-left.panel-move .back-arrow,
  .filter-panel-left .fwd-arrow,
  .filter-panel-left.panel-move .fwd-arrow {
    visibility: hidden;
  }
  .filter-panel-left {
    width: 40px;
    white-space: nowrap;
  }
  .filter-panel-left .ms-Label {
    visibility: hidden;
    white-space: nowrap;
  }
  .filter-panel-left.filter-focus .ms-Label,
  .filter-panel-left.filter-focus .ms-SearchBox-field {
    visibility: visible;
  }
  .filter-panel-left.filter-focus,
  .filter-panel-left:hover {
    width: 30%;
    max-width: 189px !important;
  }
  .filter-panel-left:hover .ms-Label,
  .filter-panel-left:hover .ms-SearchBox-field,
  .filter-panel-left.filter-focus .ms-Label,
  .filter-panel-left.filter-focus .ms-SearchBox-field {
    visibility: visible;
  }

  .filter-panel-left .ms-SearchBox.txt-filter-1 {
    border-width: 0;
    background-color: transparent;
  }
  .filter-panel-left .ms-SearchBox-field {
    visibility: hidden;
  }
  .filter-panel-left .ms-SearchBox-iconContainer {
    text-align: left;
  }
  .filter-panel-left:hover .ms-SearchBox.txt-filter-1,
  .filter-panel-left.filter-focus .ms-SearchBox.txt-filter-1 {
    border-width: 1px;
    background-color: #ffffff;
  }
  .filter-panel-left .filter-option-list button span,
  .filter-panel-left .filter-option-list a span {
    visibility: hidden;
  }
  .filter-panel-left:hover .filter-option-list button span,
  .filter-panel-left.filter-focus .filter-option-list button span,
  .filter-panel-left:hover .filter-option-list a span,
  .filter-panel-left.filter-focus .filter-option-list a span {
    visibility: visible;
  }
  .filter-panel-left .filter-option-list button i,
  .filter-panel-left .filter-option-list a i,
  .filter-panel-left .ms-SearchBox-icon {
    font-size: 20px;
    padding-right: 0;
    padding-left: 2px;
    transition: all 0.2s linear;
    font-weight: normal;
  }
  .filter-panel-left .ms-SearchBox-icon {
    padding-left: 0;
    margin-left: -2px;
  }
  .filter-panel-left .filter-option-list button i.root-143,
  .filter-panel-left .filter-option-list a i.root-143 {
    font-size: 22px;
  }
  .filter-panel-left .filter-option-list .ms-Stack button {
    margin-bottom: 15px;
  }

  .filter-panel-left:hover .filter-option-list button i,
  .filter-panel-left.filter-focus .filter-option-list button i,
  .filter-panel-left:hover .filter-option-list a i,
  .filter-panel-left.filter-focus .filter-option-list a i {
    font-size: 14px;
    padding-right: 0.5rem;
    padding-left: auto;
  }
  .filter-panel-left:hover .filter-option-list button i.root-143,
  .filter-panel-left.filter-focus .filter-option-list button i.root-143,
  .filter-panel-left:hover .filter-option-list a i.root-143,
  .filter-panel-left.filter-focus .filter-option-list a i.root-143 {
    font-size: 16px;
  }
  .filter-panel-left:hover .filter-option-list .ms-Stack button,
  .filter-panel-left.filter-focus .filter-option-list .ms-Stack button {
    margin-bottom: auto;
  }

  .filter-panel-left:hover .ms-SearchBox-icon,
  .filter-panel-left.filter-focus .ms-SearchBox-icon {
    font-size: 16px;
    padding-left: auto;
    margin-left: 8px;
  }
  .filter-panel-left .ms-TooltipHost,
  .filter-panel-left:hover .ms-TooltipHost {
    display: none;
  }
  .filter-panel-left .filter-icon,
  .filter-panel-left:hover .filter-icon {
    display: block;
  }
  .filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    display: block;
  }
  .filter-panel-left i.ms-SearchBox-icon:before {
    left: 0;
  }
  .project-detail-list .proj-detail-block .proj-detail-content.inner {
    width: calc(100% - 20px);
  }
  .bookmark-panel.parent {
    display: none;
  }
  .bookmark-panel-responsive,
  .bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
    display: block;
  }
  .bookmark-panel i,
  .bookmark-panel.parent.panel-on {
    display: inline-flex;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
  .filter-panel-left {
    width: 25%;
  }
  .project-detail-list.inner-container {
    width: 75%;
  }
  .project-detail-list.vp-inner-container {
    width: 75%;
  }
  .bookmark-panel.parent {
    width: calc(100% - 75%);
  }
}
@media only screen and (max-width: 1000px) {
  .hidden-M {
    display: none !important;
  }
}
@media only screen and (max-width: 1279px) {
  .hidden-L {
    display: none !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .hidden-XL {
    display: none !important;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .project-detail-list.inner-container {
    width: 100%;
  }
  .project-detail-list.vp-inner-container {
    width: 100%;
  }
  .ms-Grid-col.ms-lg6.grid-content-left {
    width: 52%;
  }
  .ms-Grid-col.ms-lg6.grid-content-right {
    width: 48%;
  }
}
@media only screen and (max-width: 1100px) {
  .ms-Grid-col.grid-content-left {
    width: 100%;
  }
  .ms-Grid-col.grid-content-right {
    display: none;
  }
  .bookmark-panel.parent {
    top: -1px;
    height: 100%;
  }
  .bookmark-panel-responsive {
    top: 0;
    right: 17px;
  }
  .grid-righter {
    border-right: 0;
  }
}
@media only screen and (max-width: 1440px) {
  .bookmark-panel-responsive {
    width: auto;
  }
  .bookmark-panel.parent {
    width: 220px;
  }
  .filter-panel-left .ms-TooltipHost .search-icon-only {
    display: none;
  }
  .filter-panel-left i.ms-SearchBox-icon {
    font-size: 22px;
    margin-left: -3px;
  }
}

.window-width-indicator {
  position: fixed;
  top: 6px;
  right: 15px;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.ms-DetailsHeader {
  padding-top: 0 !important;
}

.ms-DetailsList {
  min-height: 100px;
}

.qrListPane {
  height: 100% !important;
}

.is-disabled span i {
  color: #999999 !important;
}

.is-disabled label {
  color: #000000 !important;
}

.ms-Dialog-main {
  overflow: hidden;
}

.reactSelect__placeholder {
  top: 49% !important;
}
.reactSelect__indicator-separator {
  display: none !important;
}

.project-detail-list.inner-container.cpc {
  padding: 0 0 1rem 1rem;
  min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.cab {
  padding: 0 0 1rem 1rem;
  height: 76vh;
  background-color: #edebe9;
}

.title-pane {
  /* background-color: #f4681d; */
  color: white;
  border-left: 1px solid #ddd;
}

.short-cut-pane-label {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
}

.filter-title .ms-TooltipHost {
  display: block;
}

.card-container {
  background-color: #fff !important;
  width: 100%;
}
.card-footer {
  margin-top: 15px;
}
.document-pane-card {
  width: 90%;
  background: #fff;
  padding: 5px 28px 5px 28px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}
@media only screen and (max-width: 1100px) {
  .document-pane-card {
    width: 85%;
  }
  .card-container {
    background-color: #fff !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .document-pane-card {
    width: 88%;
  }
}

.document-pane-card.wbs {
  width: auto;
  background: #fff;
  padding: 5px 28px 5px 28px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
  0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
  0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.marginTop {
  margin-top: 10px;
}
.marginBottom {
  margin-bottom: 35px;
}
@media only screen and (max-width: 1100px) {
  .treeStructure-container {
    margin-bottom: 20vh !important;
  }
  .cpc-basic-unit {
    margin-top: 1px !important;
  }
}

.breadcrumb-bar {
  border: 1px solid rgb(96, 94, 92);
  height: 30px;
  margin-top: 0;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
  .tree-view-field {
    width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .tree-view-field {
    width: 88%;
  }
}
@media only screen and (min-width: 1281px) {
  .tree-view-field {
    width: 92%;
  }
}

a.cke_button {
  display: inline-block !important;
  height: 18px !important;
  padding: 4px 6px !important;
  outline: 0 !important;
  cursor: default !important;
  /* float: left !important; */
  border: 0 !important;
  position: relative !important;
  width: auto !important;
}

a.cke_path_item,
span.cke_path_empty {
  display: inline-block !important;
  /* float: left !important; */
  padding: 3px 4px !important;
  margin-right: 2px !important;
  cursor: default !important;
  text-decoration: none !important;
  outline: 0 !important;
  border: 0 !important;
  color: #484848 !important;
  font-weight: bold !important;
  font-size: 11px !important;
  width: auto !important;
}

.sub-header-title {
  border-bottom: 1px solid #bab8b7;
}

.reactSelect__menu {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important ;
  font-size: 14px !important ;
  font-weight: 400 !important ;
}

.reactSelect__single-value {
  font-size: 14px !important ;
  font-weight: 400 !important ;
}

.costTable_header {
  box-sizing: border-box;
  border-bottom: 1px black solid;
}
.costTable_header_col {
  width: 200px;
}

.progress-statement .ms-DetailsList-headerWrapper .ms-DetailsRow {
  background: #faf9f8;
}

/*collapse BookmarkPane*/
.collapse-bookmark-panel.parent,
.collapse-bookmark-panel-responsive {
  width: 40px;
  height: 20rem;
  position: absolute;
  top: 100px;
  right: -1px;
  background-color: #ffffff;
  border: 1px solid #bab8b7;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
}
.collapse-bookmark-panel.parent {
  width: 40px;
  /*// height: calc(100% - 3.75rem);*/
  height: calc(100vh - 174px);
  top: 43px;
  z-index: 1;
  border: 1px solid #d2d2d2;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.collapse-bookmark-panel-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.collapse-bookmark-panel.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.collapse-bookmark-panel.parent label {
  padding: 1px 0;
}
.collapse-bookmark-panel.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.collapse-bookmark-panel.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.collapse-bookmark-panel.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.collapse-bookmark-panel-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.collapse-bookmark-panel-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.collapse-bookmark-panel-responsive i:hover {
  color: #854114;
}
.collapse-bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.collapse-bookmark-panel .inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.collapse-bookmark-panel .inner-container label.selected,
.collapse-bookmark-panel .inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.collapse-bookmark-panel .inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.collapse-bookmark-panel .inner-container label.selected {
  border-left: 0;
}

.totalAmountCol {
  text-align: right;
}

.shortCutPaneWrapper {
  min-height: calc(100vh - 129px) !important;
}
.PScostTable .is-row-header {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.subShortCutPane {
  margin-left: 18px;
  margin-bottom: 5px;
}
.filter-panel-left.panel-move .subShortCutPane {
  margin-left: 1px !important;
  margin-bottom: 5px;
}

.splitPopup .ms-Grid .ms-Grid-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.scrollablePane {
  position: inherit;
  display: grid;
}
.purchase-order .ms-DetailsList-headerWrapper .ms-DetailsRow {
  background: #faf9f8;
}

.PoChoiceGroup .ms-ChoiceFieldGroup-flexContainer {
  display: flex !important;
}
.PoChoiceGroup {
  display: flex !important;
  margin-top: -3px !important;
  min-height: 40px !important;
}
.PoChoiceGroup .ms-ChoiceField-input {
  z-index: 10000000 !important;
}
.PoChoiceGroupProduct .ms-ChoiceFieldGroup-flexContainer {
  display: flex !important;
}
.PoChoiceGroupProduct {
  display: flex !important;
  margin-top: 0px !important;
  min-height: 40px !important;
}
.PoChoiceGroupProduct .ms-ChoiceField-input {
  z-index: 10000000 !important;
}

.poDeliveryRowItem {
  min-height: 30px;
  margin-top: 7px;
}

/*image preview modal*/

/* The Modal (background) */
.imageModal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.imageModal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Add Animation */
.imageModal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.imageModalClose {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.imageModalClose:hover,
.imageModalClose:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .imageModal-content {
    width: 100%;
  }
}

.resourceIcon i {
  color: rgb(255, 77, 77) !important;
}

.ck {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}
.ck-content {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}

.ck-editor__editable {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}

.mail-template-fonts {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
}

.PoDatePicker {
  padding-bottom: 0px;
}
.PoUnitPrice {
  padding-bottom: 5px;
}

/* Visual Plan style*/
.ViewContainer {
  list-style: none;
  -ms-box-orient: horizontal;

  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  align-items: center;
}

.Button {
  background-color: #e7e7e7;
  color: black;
  border: none;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.Switch {
  margin: 4px 15px;
  font-size: 14px;
  font-family: 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue';
  display: flex;
  justify-content: center;
  align-items: center;
}
.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 5px;
}

.Switch_Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .Slider {
  background-color: #2196f3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Visual Plan style*/

.project-detail-list.full-inner-container.vp {
  padding: 0 0 1rem 1rem;
  width: calc(100% - 40px);
  min-height: calc(33vh) !important;
  height: calc(33vh) !important;
}
.project-detail-list.inner-container.vp {
  padding: 0 0 1rem 1rem;
  min-height: calc(33vh) !important;
  height: calc(33vh) !important;
}
.project-detail-list.vp-full-inner-container {
  padding: 0 0 0 1rem;
  width: calc(100% - 40px);
  border-right: 1px solid #bab8b7;
}
.project-detail-list.vp-inner-container {
  padding: 0 0 0 1rem;
  width: 80%;
  border-right: 1px solid #bab8b7;
}

/*vp BookmarkPane*/

.bookmark-panel-vp.parent,
.bookmark-panel-vp-responsive {
  width: calc(100% - 80%);
  position: absolute;
  top: 100px;
  right: 0px;
  background-color: #ffffff;
  border: none !important;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
  height: auto;
}
.bookmark-panel-vp.parent {
  width: calc(100% - 80%);
  /*// height: calc(100% - 3.75rem);*/
  top: 53px;
  z-index: 1;
  border: none !important;
  height: auto;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.bookmark-panel-vp-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.bookmark-panel-vp.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.bookmark-panel-vp.parent label {
  padding: 1px 0;
}
.bookmark-panel-vp.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.bookmark-panel-vp.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.bookmark-panel-vp.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.bookmark-panel-vp.parent.collapse i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel-vp-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.bookmark-panel-vp-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.bookmark-panel-vp-responsive i:hover {
  color: #854114;
}
.bookmark-panel-vp.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.bookmark-panel-vp.inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel-vp.inner-container label.selected,
.bookmark-panel-vp.inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel-vp.inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel-vp.inner-container label.selected {
  border-left: 0;
}
.inner-container-pmol a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel-vp.inner-container-pmol label.selected,
.bookmark-panel-vp.inner-container-pmol a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel-vp.inner-container-pmol label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel-vp.inner-container-pmol label.selected {
  border-left: 0;
}

.collapse-bookmark-panel-vp.parent,
.collapse-bookmark-panel-vp-responsive {
  width: 40px;
  position: absolute;
  top: 100px;
  right: 0px;
  background-color: #ffffff;
  border: none !important;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
  height: auto;
}
.collapse-bookmark-panel-vp.parent {
  width: 40px;
  /*// height: calc(100% - 3.75rem);*/
  top: 53px;
  z-index: 1;
  border: none !important;
  height: auto;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.collapse-bookmark-panel-vp-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.collapse-bookmark-panel-vp.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.collapse-bookmark-panel-vp.parent label {
  padding: 1px 0;
}
.collapse-bookmark-panel-vp.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.collapse-bookmark-panel-vp.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.collapse-bookmark-panel-vp.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.collapse-bookmark-panel-vp-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.collapse-bookmark-panel-vp-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.collapse-bookmark-panel-vp-responsive i:hover {
  color: #854114;
}
.collapse-bookmark-panel-vp.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.collapse-bookmark-panel-vp.inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.collapse-bookmark-panel-vp.inner-container label.selected,
.collapse-bookmark-panel-vp.inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.collapse-bookmark-panel-vp.inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.collapse-bookmark-panel-vp.inner-container label.selected {
  border-left: 0;
}

.splitter {
  width: 100%;
  /*height: 100px;*/
  display: flex;
}

#separator {
  /*cursor: col-resize;*/
  background-color: #aaa;
  /*background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>");*/
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  width: 100%;
  cursor: row-resize;

  /* Prevent the browser's built-in drag from interfering */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#first {
  background-color: #dde;
  /*width: 50%;*/
  height: 43%;
  min-width: 10px;
}

#second {
  background-color: #eee;
  /*width: 50%;*/
  height: 43%;
  min-width: 10px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 50ms ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 5px;
  margin: 0 -5px;
  border-left: 2px solid rgba(255, 255, 255, 0);
  border-right: 2px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  width: 11px;
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.Chart .rst__virtualScrollOverride {
  width: 456px !important;
}
.gantt-module_horizontalContainer_1Ns26 {
  /*height: 630px!important;*/
  min-height: 400px;
}
@media screen and (min-width: 1280px) {
  /*For Big TV's (HD Screens) */
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
  }
}

@media screen and (min-width: 1920px) {
  /*For Projectors or Higher Resolution Screens (Full HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height:300px!important;*/
    /*background: yellow;*/
  }
}
@media screen and (min-width: 1921px) and (max-width: 3839px) {
  /*For 2K Displays (Ultra HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
    /*background: green;*/
  }
}
@media screen and (min-width: 3840px) {
  /*For 4K Displays (Ultra HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
    /*background: blue;*/
  }
}

.grid-righter.column-filter-activated.vp {
  width: calc(100vw - 190px) !important;
}

.grid-righter.column-filter-activated.vpWithDoc {
  width: 50vw !important;
}

.project-detail-list.four-pane-full-inner-container {
  padding: 0 0 1rem 1rem;
  width: calc(100%) !important;
  height: 100%;
}

.vpDatePicker .ms-DatePicker {
  width: 200px;
}

.vpDatePicker180 .ms-DatePicker {
  width: 180px;
}

.vertical-scroll-module_scroll_3WGFv {
  overflow: hidden;
}

.full-inner-container.pbs-doc-pane {
  height: calc(100vh - 218px) !important;
}

.projectAccess .ms-Checkbox-text {
  width: 100px;
}

.project_filter_pp .ms-BasePicker-text {
  min-width: 130px;
}

.docPane {
  height: 100% !important;
}

.chart .ReactVirtualized__Grid__innerScrollContainer > div:nth-child(even) {
  background-color: #f5f5f5;
}

.chart .rstcustom__row {
  padding-left: 0px !important;
  flex: 1;
}

.chart .rstcustom__expandButton {
  padding-left: 2px !important;
  padding-right: 5px !important;
  width: 40px !important;
}

.chart .rstcustom__collapseButton {
  padding-left: 2px !important;
  margin-right: 5px !important;
  width: 40px !important;
}

.chart .rstcustom__rowLabel {
  padding-right: 0px !important;
}

.chart .ms-TextField-fieldGroup {
  margin-left: -40px !important;
}

.chart .ReactVirtualized__Grid {
  overflow: hidden !important;
}

.chart .rst__virtualScrollOverride {
  border-right: 2px solid #e4e8eb !important;
}

.pmol.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: 100% !important;
}

.pbs.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: calc(100vh - 218px);
}

.dayPlanning.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  /* .SplitPane .Pane1 {
  width: 100% !important
} */
  z-index: 11;
}

.projectDayPlanning.filter-panel-left {
  height: 100% !important;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
}

.timeclock.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  /* .SplitPane .Pane1 {
  width: 100% !important
} */
}

.vp.filter-panel-left {
  background-color: #fafafa;

  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 15%;
}

.chart .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  /*margin-left: 10px!important;*/
  -webkit-transform: rotate(-45deg);
}

.chart .rstcustom__expandButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.cbcTaxonomy .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  left: 2px;
}

.chart .rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  /*padding: 3px!important;*/
  margin-left: 10px !important;
  -webkit-transform: rotate(45deg);
}

.chart .rstcustom__expandButton {
  padding-right: 0px !important;
}

.chart .rstcustom__collapseButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(45deg);
}

.org .rstcustom__collapseButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__collapseButton {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding-right: 15px;
}

.org .rstcustom__expandButton {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding-right: 15px;
}

.org .rstcustom__expandButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
}

.chart .rstcustom__nodeContent {
  width: 100% !important;
}

.chart .rstcustom__toolbarButton {
  background-color: transparent !important;
}

.chart .rstcustom__rowContents {
  background-color: transparent !important;
}

.chart .rstcustom__rowSearchFocus {
  background-color: #a3e4d7 !important;
  outline: #a3e4d7;
}
.dp-dot {
  width: 24px;
  margin-top: -17px;
  margin-right: -1px;
  float: right;
}
.dp-dot .ms-CommandBar-overflowButton {
  background-color: rgb(13 63 106) !important;
  width: 28px !important;
  min-width: 20px !important;
  padding: 0 !important;
}
.dp-dot .ms-OverflowSet.ms-CommandBar-primaryCommand {
  color: #ffffff;
  width: 24px !important;
  min-width: 20px !important;
  padding: 0 !important;
}

.personSquare {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto 10px;
  position: absolute;
  margin-top: 18px;
  margin-left: 10px;
  z-index: 1;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
}

.gantt_ganttVerticalContainer__3R4gT {
  height: 1670px !important;
  overflow-x: scroll !important;
}

.gantt_ganttVerticalContainer__3R4gT
  .gantt4k
  .gantt_horizontalContainer__3cfv6 {
  height: 1670px !important;
  /* width: 100% !important; */
  overflow-x: scroll !important;
}
.resizable-fragment .gantt4k .gantt_horizontalContainer__3cfv6 {
  height: 1670px !important;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  overflow: hidden !important;
}

.gantt4k {
  height: 1670px !important;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  overflow: hidden !important;
}

.resizable-fragment .gantt_horizontalContainer__3olJ8 {
  height: 612px !important;
}

.horizontal-scroll_scroll__1TmDb {
  display: none !important;
}

.pdp .ms-Panel-header {
  position: absolute;
  top: 0;
  width: 98% !important;
  margin-top: 8px;
}

.ReactVirtualized__Grid .ReactVirtualized__List .rst__virtualScrollOverride {
  scroll-padding-bottom: 20;
}

.planboard-calender .ms-DatePicker div:nth-child(2) {
  padding: 2px !important;
}

.rfq-mobile-list .ms-DetailsHeader-cellName,
.rfq-mobile-list .ms-DetailsRow-cell {
  font-size: 25px !important;
}

.rfq-mobile .ms-TextField-fieldGroup {
  height: 65px !important;
}

.SplitPane .Resizer {
  z-index: 11 !important;
}

.ciaw-eligibility-list-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

 /* table,
th, td {
  border: '2px solid black';
} */

.matrixTable table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.expandRowMT td:first-child {
  position: sticky;
  left: 0;

}

.matrixTable table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

.matrixTable table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}

.fullWidthWithControlledHeight .reactSelect__menu-list{
  max-height: 100px!important;
}

.tree-view-field-in-modal {
  width: 90%;
}

.tree-aside-content{
  display: flex;
  align-items: center;
  min-width: 60px;
}

.date {
  cursor: pointer;
}
/*.tree-aside-content.Component,.date{*/
/*  min-width: 60px!important;*/
/*}*/
/*.tree-aside-content.Component,.date{*/
/*  min-width: 60px!important;*/
/*}*/



.loading-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.fui-Listbox{
  max-height: 600px;
}


.project-day-planning-shortcut-pane{
  height: 100% !important;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  overflow: auto !important;;
}

.filter-panel-left.panel-move .menu-item, .filter-panel-left.panel-move .fui-MenuItem__submenuIndicator{
  display: none;
}
.filter-panel-left.panel-move {
  width: 40px!important;
}

.full-height {
  height: 100%;
}

/*mid term planning organisation taxonomy gantt chart styles*/

#orgGantt .gantt4k {
  height: auto!important;
}

#orgGantt > :first-child {
  height: 100%;
  overflow: hidden;
}

#orgGantt [class^="gantt_wrapper"] {
  height: inherit;
  overflow: scroll;
}

#orgGantt .resizable-fragment {
  /*height: auto;*/
  overflow: hidden;
}

#orgGantt [class^="gantt_wrapper"] > :first-child {
  height: auto!important;
}

#orgGantt [class^="gantt_wrapper"] > :nth-child(2) {
  height: 20px!important;
}

#orgGantt .rst__tree :first-child :first-child  {
  /*overflow: hidden;*/
}

/*mid term planning top taxonomy table*/

#midTermOrgGantt .rst__tree > * > * {
  overflow: hidden;
}

#midTermOrgGantt .rstcustom__expandButton, #midTermOrgGantt .rstcustom__collapseButton {
  z-index: 0;
}


.wbs__command__bar{
  height: 27px;
  border-bottom: 1px solid #d2d2d2;
  background-color: rgb(255, 255, 255);
}
.wbs__form-container{
  height: calc(100% - 28px);
  overflow: auto;
  display: flex;
}

.wbs__card{
  margin: 15px;
  background: #fff;
  padding: 5px 28px 5px 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ck-small-toolbar .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items{
  height: 25px!important;;
}
.ck-small-toolbar .ck.ck-button, a.ck.ck-button {
  height: 20px!important;;
  min-height:20px!important;
}
.ck-small-toolbar .ck.ck-icon, .ck.ck-icon * {
  height: 15px!important;
}

.projectSelect [class^="reactSelect__control"] {
  min-height: 32px;
  height: 32px;
}
