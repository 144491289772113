.navBar {
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.1px solid #EAECEE;
}

.commandBar {
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    float: right;
    width: 100%;
    border-bottom: 2px solid #EAECEE;
}

.navicon {
    margin-left: 10px;
    size: 40px;
    color: black;
    font-size: 25px;
    margin-right: 15px;
}

.brandName {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.menuCardIcon {
    margin-left: 25px;
    size: 60px;
    color: black;
    font-size: 25px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}
