:root {
    --horizontal-resizer: 328px;
}

.wrapper {
    height: calc(100vh - 14vh) !important;
    position: relative;
}

.selected {
    font-weight: bold;
    color: #854114 !important;
}

.hr {
    margin-bottom: 15px;
    border-top: 1px solid #d2d2d2;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 49%;
}

.taxonomy-wrapper {
    height: calc(var(--horizontal-resizer, 1820px) - 354px);
    margin-bottom: 10px;
    width: max-content;
}

.sortable-tree-wrapper {
    width: 100%;
    overflow: overlay;
}