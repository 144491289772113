.projectWrapper {
    cursor: pointer;
    outline: none;
}

.projectBackground {
    user-select: none;
    opacity: 0.6;
}

.projectTop {
    user-select: none;
}

.productWrapper {
    cursor: pointer;
    outline: none;
    bottom: 0;
    margin-bottom: -10
}
