.barWrapper {
    cursor: pointer;
    outline: none;
}

.barWrapper:hover .barHandle {
    visibility: visible;
    opacity: 1;
}

.barHandle {
    fill: #ddd;
    cursor: ew-resize;
    opacity: 0;
    visibility: hidden;
}

.barBackground {
    user-select: none;
    stroke-width: 0;
}

.contextMenu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
}

.projectTop {
    user-select: none;
}

