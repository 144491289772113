.projectBar {
    background-color: #fcfcfc;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.projectBaricon {
    margin-left: 10px;
    size: 30px;
    color: black;
    font-size: 22px;
    margin-right: 15px;
    color: #f7d166 !important;
}

.brandName {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.waypoint i {
    color: #f7d166 !important;
}
