.full-width {
    width: 100%;
}

.main-layout-document-pane-stack {
    width: 100%;
    height: 80.8vh;
    overflow: auto;
    align-self: center;
    padding: 1rem;
}
